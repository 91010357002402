import { Navigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const UnmatchedRouteHandler = () => {
  const { authState } = useOktaAuth();
  if (authState !== null) {
    if (authState?.isAuthenticated) {
      return <Navigate to="/claims" />;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

export default UnmatchedRouteHandler;
