var CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
var ISSUER = process.env.REACT_APP_OKTA_ISSUER;
var OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
var BASENAME = '';
var OKTA_SCOPES = process.env.REACT_APP_OKTA_SCOPES.split(",");
var REDIRECT_URI = "".concat(window.location.origin).concat(BASENAME, "/login/callback");
export default {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: OKTA_SCOPES,
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    app: {
        basename: BASENAME,
    },
};
