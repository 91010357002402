var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../../models/constants";
import { accountGetDatasets, dataSetGetPerils } from "../../actions/company-admin/company-admin-actions";
var initialState = {
    loadingDatasets: IDLE,
    loadingPerils: IDLE,
    datasets: [],
    totalDatasets: 0,
    totalPerils: 0,
    selectedDataset: null,
    perils: []
};
export var companyAdminSlice = createSlice({
    name: "companyAdmins",
    initialState: initialState,
    reducers: {
        handleSelectedDataset: function (state, _a) {
            var payload = _a.payload;
            state.selectedDataset = payload;
        },
        handleRemovePerilFromStore: function (state, _a) {
            var payload = _a.payload;
            return __assign(__assign({}, state), { perils: state.perils.filter(function (peril) { return peril.perilId !== payload; }) });
        },
        handleRemoveCauseFromStore: function (state, _a) {
            var payload = _a.payload;
            var causeId = payload === null || payload === void 0 ? void 0 : payload.causeId;
            var selectedPerilIndex = payload === null || payload === void 0 ? void 0 : payload.selectedPerilIndex;
            return __assign(__assign({}, state), { perils: state.perils.map(function (peril, index) {
                    if (index === selectedPerilIndex) {
                        return __assign(__assign({}, peril), { causes: peril.causes.filter(function (cause) { return (cause === null || cause === void 0 ? void 0 : cause.causeId) !== causeId; }) });
                    }
                    return peril;
                }) });
        },
    },
    extraReducers: function (builder) {
        builder.addCase(accountGetDatasets === null || accountGetDatasets === void 0 ? void 0 : accountGetDatasets.pending, function (state) {
            state.loadingDatasets = LOADING;
        });
        builder.addCase(accountGetDatasets === null || accountGetDatasets === void 0 ? void 0 : accountGetDatasets.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.datasets = payload.data;
            state.totalDatasets = payload.total;
            state.loadingDatasets = SUCCEEDED;
        });
        builder.addCase(accountGetDatasets === null || accountGetDatasets === void 0 ? void 0 : accountGetDatasets.rejected, function (state) {
            state.loadingDatasets = FAILED;
        });
        builder.addCase(dataSetGetPerils === null || dataSetGetPerils === void 0 ? void 0 : dataSetGetPerils.pending, function (state) {
            state.loadingPerils = LOADING;
        });
        builder.addCase(dataSetGetPerils === null || dataSetGetPerils === void 0 ? void 0 : dataSetGetPerils.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.perils = payload.data;
            state.totalPerils = payload.total;
            state.loadingPerils = SUCCEEDED;
        });
        builder.addCase(dataSetGetPerils === null || dataSetGetPerils === void 0 ? void 0 : dataSetGetPerils.rejected, function (state) {
            state.loadingPerils = FAILED;
        });
    },
});
export var handleSelectedDataset = (_a = companyAdminSlice.actions, _a.handleSelectedDataset), handleRemovePerilFromStore = _a.handleRemovePerilFromStore, handleRemoveCauseFromStore = _a.handleRemoveCauseFromStore;
export default companyAdminSlice.reducer;
export var getDatasets = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.companyAdminState) === null || _a === void 0 ? void 0 : _a.datasets; };
export var loadingDatasetsStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.companyAdminState) === null || _a === void 0 ? void 0 : _a.loadingDatasets; };
export var getDatasetsCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.companyAdminState) === null || _a === void 0 ? void 0 : _a.totalDatasets; };
export var getSelectedDataset = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.companyAdminState) === null || _a === void 0 ? void 0 : _a.selectedDataset; };
export var getPerils = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.companyAdminState) === null || _a === void 0 ? void 0 : _a.perils; };
export var getPerilsCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.companyAdminState) === null || _a === void 0 ? void 0 : _a.totalPerils; };
