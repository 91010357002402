import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import NavBarStyles from "./styles/NavBarStyles";

export default styled(Drawer)(({ theme, ownerState }) => {

  const { boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, miniSidenav } = ownerState;
  const sidebarWidth = NavBarStyles?.navigationBarAllignment?.sidebarWidth;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;
  const heightNavBar =  NavBarStyles?.navigationBarAllignment?.height
  const marginNavBar =  NavBarStyles?.navigationBarAllignment?.margin
 
  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: NavBarStyles?.base?.veriskBlue,
    textColor : "white",
    transform: "translateX(0)",

    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      
    },
  });

  // styles for the sidenav when we reduce the window size
  const drawerCloseStyles = () => ({
    background: NavBarStyles?.base?.veriskBlue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
  
    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",
      height : heightNavBar,
   
      borderRadius : 0,
      ...(drawerOpenStyles()),

    },
  };
});
