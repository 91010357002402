import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from './context';
import store from './core/store/index';
import { Provider } from 'react-redux';
import { MessageProvider } from './context/MessageProvider';

import { css, Global } from '@emotion/react';
import { ErrorBoundaryWrapper } from './ErrorBoundaryWrapper';

const globalStyles = css`
  body {
    margin: 0;
  }
`;

const container = document.getElementById('app');
const root = createRoot(container);
if (process.env.NODE_ENV === 'production') {
  // Disable React DevTools
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    for (const [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === 'function' ? () => {} : null;
    }
  }
}
root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <Global styles={globalStyles} />
        <ErrorBoundaryWrapper>
          <MessageProvider>
            <App />
          </MessageProvider>
        </ErrorBoundaryWrapper>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
