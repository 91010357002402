import { SvgIcon } from "@mui/material"
import { svgIconStyles } from "../styles/NavBarStyles"
import SidenavCollapse from "../SidenavCollapse"
import { NavLink } from "react-router-dom"
import {ReactComponent as Logout} from "../../../assets/images/icons/logout.svg"
import PropTypes from "prop-types"


export const ExitButton = ({navigateTo}) =>
{
    return  (
        <NavLink key="SaveExit" to={navigateTo} style={{ textDecoration: 'none' }}>
            <SidenavCollapse name="Save & Exit" 
            icon={
                 <SvgIcon {...svgIconStyles}>
                    <Logout />
                </SvgIcon>
                }
        />
        </NavLink>
     )
}


ExitButton.defaultProps = {
    navigateTo: "",
  };
  

  ExitButton.propTypes = {
    navigateTo : PropTypes.string
  };