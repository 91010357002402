import { createSlice } from "@reduxjs/toolkit";
import { userGetUsers } from "../../../actions/company-admin/users/user-actions";
import { DEFAULT_PAGE, FAILED, IDLE, LOADING, SUCCEEDED } from "../../../models/constants";
var initialState = {
    loadingUsers: IDLE,
    users: [],
    totalUsers: 0,
    pageNumber: DEFAULT_PAGE
};
export var usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(userGetUsers === null || userGetUsers === void 0 ? void 0 : userGetUsers.pending, function (state) {
            state.loadingUsers = LOADING;
        });
        builder.addCase(userGetUsers === null || userGetUsers === void 0 ? void 0 : userGetUsers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.users = payload.data;
            state.totalUsers = payload.total;
            state.loadingUsers = SUCCEEDED;
        });
        builder.addCase(userGetUsers === null || userGetUsers === void 0 ? void 0 : userGetUsers.rejected, function (state) {
            state.loadingUsers = FAILED;
        });
    },
});
export default usersSlice.reducer;
export var getUsers = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.userState) === null || _a === void 0 ? void 0 : _a.users; };
export var loadingUsersStatus = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.userState) === null || _a === void 0 ? void 0 : _a.loadingUsers; };
export var getUsersCount = function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.userState) === null || _a === void 0 ? void 0 : _a.totalUsers; };
