import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { getSuperUserStatus } from './core/reducers/auth-reducer';
import { superUserRoutes } from './routes';

export const SecureRoute = ({ children, isSuperUserRoute }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const location = useLocation();
  const isSuperUser = useSelector(getSuperUserStatus);

  if (!authState) {
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    const currentPath = location.pathname;
    oktaAuth.setOriginalUri(currentPath);
    return <Navigate to="/login" />;
  }
  if (isSuperUser !== null) {
    if (!isSuperUser && isSuperUserRoute) {
      const currentPath = location.pathname;
      oktaAuth.setOriginalUri(currentPath);
      return <Navigate to="/claims" />;
    }
  }
  return children;
};

SecureRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isSuperUserRoute: PropTypes.bool,
};

SecureRoute.defaultProps = {
  isSuperUserRoute: false,
};
