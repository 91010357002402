
import colors from "../../../assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../assets/theme/functions/pxToRem";

const { white } = colors;


const sidenav = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: pxToRem(250), // Default width

      whiteSpace: 'nowrap',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),

      // Responsive widths using breakpoints
      [theme.breakpoints.down('xl')]: {
        width: pxToRem(240),
      },
      [theme.breakpoints.down('lg')]: {
        width: pxToRem(220),
      },
      [theme.breakpoints.down('md')]: {
        width: pxToRem(200),
      },
      [theme.breakpoints.down('sm')]: {
        width: pxToRem(180),
      },
      [theme.breakpoints.down('xs')]: {
        width: pxToRem(160),
      },
    }),

    paper: ({ theme }) => ({
      backgroundColor: theme.palette.white.main, // Ensure 'white' is defined in your palette
      height: '100%',
      margin: 0,
      borderRadius: 0,
      border: 'none',
      width: pxToRem(250), // Default width to match root

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),

      // Responsive widths using breakpoints
      [theme.breakpoints.down('xl')]: {
        width: pxToRem(240),
      },
      [theme.breakpoints.down('lg')]: {
        width: pxToRem(220),
      },
      [theme.breakpoints.down('md')]: {
        width: pxToRem(200),
      },
      [theme.breakpoints.down('sm')]: {
        width: pxToRem(180),
      },
      [theme.breakpoints.down('xs')]: {
        width: pxToRem(160),
      },
    }),

    paperAnchorDockedLeft: {
      borderRight: 'none',
    },
  },
};

export default sidenav;
