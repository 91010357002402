import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';
import PropTypes from 'prop-types';
export function ErrorBoundaryWrapper({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reload the page
        window.location.reload();
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

ErrorBoundaryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
