import React, { createContext, useContext, useState, useCallback } from 'react';
import { Snackbar, Alert, Backdrop, Button } from '@mui/material';
import pxToRem from '../assets/theme/functions/pxToRem';
import PropTypes from 'prop-types';
import MDButton from '../components/MDButton';

const MessageContext = createContext();

// Provider component
export const MessageProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState({ open: false, message: '' });
  const [actionMessage, setActionMessage] = useState({ open: false, message: '' });
  const [errorMessage, setErrorMessage] = useState({ open: false, message: '' });

  const [onProceed, setOnProceed] = useState(() => () => {});
  const showSuccessMessage = useCallback((message) => {
    setSuccessMessage({ open: true, message });
  }, []);

  const showErrorMessage = useCallback((message) => {
    setErrorMessage({ open: true, message });
  }, []);

  const closeSuccessMessage = useCallback(() => {
    setSuccessMessage({ open: false, message: '' });
  }, []);

  const closeErrorMessage = useCallback(() => {
    setErrorMessage({ open: false, message: '' });
  }, []);

  const showActionMessage = (message, proceedAction) => {
    setActionMessage({ open: true, message });
    setOnProceed(() => () => {
      proceedAction(); // Execute the passed action
      closeActionMessage(); // Close the action message afterward
    });
  };

  const setProceedAction = useCallback((action) => {
    setHandleProceed(action);
  }, []);
  const closeActionMessage = useCallback(() => {
    setActionMessage({ open: false, message: '' });
  }, []);

  return (
    <MessageContext.Provider
      value={{ showSuccessMessage, showErrorMessage, showActionMessage, setProceedAction }}
    >
      {children}
      <SuccessMessageComponent
        open={successMessage.open}
        message={successMessage.message}
        onClose={closeSuccessMessage}
      />
      <ErrorMessageComponent
        open={errorMessage.open}
        message={errorMessage.message}
        onClose={closeErrorMessage}
      />

      <ActionMessageComponent
        open={actionMessage.open}
        message={actionMessage.message}
        onClose={closeActionMessage}
        onProceed={onProceed}
        onCancel={closeActionMessage}
      />
    </MessageContext.Provider>
  );
};

// Custom hook to use the message context
export const useMessage = () => useContext(MessageContext);

// Success Message Component
const SuccessMessageComponent = ({ open, message, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={4000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert
      onClose={onClose}
      icon={false}
      closeText=""
      variant="filled"
      sx={{
        width: '100%',
        backgroundColor: '#333333',
        color: '#ffffff',
        fontWeight: 'regular',
        fontSize: pxToRem(15),
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

// Error Message Component
const ErrorMessageComponent = ({ open, message, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={4000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Alert onClose={onClose} severity={'error'} variant="filled" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);

const ActionMessageComponent = ({ open, message, onClose, onProceed, onCancel }) => (
  <>
    {/* Backdrop is shown only when Snackbar is open */}
    <Backdrop
      open={open}
      onClick={onClose}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    ></Backdrop>
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={onClose}
        icon={false}
        closeText=""
        variant="filled"
        sx={{
          width: '100%',
          backgroundColor: 'white',
          color: 'Black',
          fontWeight: 'regular',
          fontSize: pxToRem(15),
        }}
        action={
          <>
            <Button
              size="small"
              onClick={onProceed}
              pt={0}
              sx={{
                color: 'black',

                height: '100%',
              }}
            >
              Proceed
            </Button>

            <Button
              color="error"
              size="small"
              onClick={onCancel}
              sx={{
                height: '100%',
              }}
            >
              Cancel
            </Button>
          </>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  </>
);

SuccessMessageComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

ErrorMessageComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

ActionMessageComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

MessageContext.propTypes = {
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
};

MessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
