export var EndPoints = {
    // claims
    auth: 'auth',
    claims: 'claims',
    assignments: 'assignments',
    dashboard: 'dashboard',
    accounts: 'accounts',
    superUser: 'superusers',
    datasets: 'datasets',
    company: 'company'
};
