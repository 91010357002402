import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";

import MDBox from "../MDBox";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";

import { useMaterialUIController, setMiniSidenav, setTransparentSidenav,  setWhiteSidenav, setPageLoading } from "../../context";
import {ReactComponent as Logout} from "../../assets/images/icons/logout.svg" 
import { svgIconStyles } from "./styles/NavBarStyles";
import { TitleSection } from "./TitleSection";
import SvgIcon from '@mui/material/SvgIcon'
import { getIsClaimSelected } from "../../core/reducers/claims-reducer";
import { useSelector } from "react-redux";
import { useOktaAuth } from '@okta/okta-react';
import { useMessage } from "../../context/MessageProvider";
import { ExitButton } from "./components/ExitButton";
function Sidenav({  brand, brandName, routes, ...rest }) {
  const { oktaAuth } = useOktaAuth();
  const isClaimSelected = useSelector(getIsClaimSelected);
  
  const { showErrorMessage } = useMessage();
 
  const [controller, dispatch] = useMaterialUIController(); 
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode,layout } = controller;
  const location = useLocation();
  const navigateTo = (layout === 'superUser' || layout === 'dashboard')
  ? "/claims"
  : "/superuser/accounts";
  const handleLogout = async()=>{
    try {
      setPageLoading(dispatch,true)
      await oktaAuth.signOut ({ postLogoutRedirectUri: window.location.origin + '/login' });
    
    } catch (err) {
      showErrorMessage("Something Went Wrong")
    }
    finally{
      setPageLoading(dispatch,false);
    }
  }

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location])


  const renderRoutes = routes.map(({ name, icon, key,  route }) => {
    let collapseName="";
    const lastSlashIndex=location.pathname.replace(/\/+$/, '').lastIndexOf("/");
    if(lastSlashIndex!== -1)
      collapseName=location.pathname.substring(lastSlashIndex + 1);
  
    const navLinkUrl = route?.replace(':id', location.pathname.split('/')[2]);
    
    const  returnValue =  (
        <NavLink key={key} to={navLinkUrl} style={{ textDecoration: 'none' }}>
          <SidenavCollapse name={name} icon={icon} active={key.trim() === collapseName.replace(/\/+$/, '').trim()} />
        </NavLink>
      );
    
    return returnValue;
  });
 
  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <TitleSection brandName ={brandName} />
      <List>{renderRoutes}</List>
      <MDBox  p={2} pl={0} mt="auto">
        {
          (isClaimSelected || layout==='superUser' || layout==='companyAdmin')
            ?
            (
            <ExitButton navigateTo={navigateTo} />  
            )
            :
            (
            <SidenavCollapse name="Logout" icon={<SvgIcon {...svgIconStyles}>
            <Logout />
            </SvgIcon>}  onClick={handleLogout} />
            )
        }
      </MDBox>
      
    </SidenavRoot>
  );
}


Sidenav.propTypes = {
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
